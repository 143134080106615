<script>
  import Vue from 'vue';
  import GlobalVue from '@helper/Global.vue';
  export default {
    extends: GlobalVue,
    data() {
			return {
				data: false,
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
		}
  }
</script>

<template>
  <div>
    <h1>Topbar</h1>
  </div>
</template>